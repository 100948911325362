import {widgetDataHooks as DH} from '@wix/wix-events-data-hooks'
import classNames from 'classnames'
import React, {useEffect, useRef} from 'react'
import {useWidgetState} from '../../hooks/state-provider'
import {useEventsList} from '../../hooks/use-events-list'
import {getCompId} from '../../selectors/component'
import {focusFirstNewEvent} from '../../utils/a11y'
import {LoadMoreButton} from '../load-more-button'
import {MainListTitle} from '../main-list-title'
import SideBySideItem from './side-by-side-item'
import s from './side-by-side.scss'
import {Utils} from './utils'
import {SideBySideProps} from '.'

export const SideBySide = ({t, width}: SideBySideProps) => {
  const utils = new Utils()
  const containerRef = useRef<HTMLDivElement>()
  const {events, hasMore, moreLoading, pageLimit} = useEventsList()
  const eventsCount = events.length
  const compId = useWidgetState(getCompId)

  useEffect(() => {
    utils.setItemsHeight()

    focusFirstNewEvent({eventsCount, pageLimit, hasMore, compId})
  }, [eventsCount])

  return (
    <div className={s.container} ref={containerRef}>
      <MainListTitle />
      <div className={s.items}>
        <ul data-hook={DH.sideBySideItems}>
          {events.map((event: ExtendedEvent, index: number) => (
            <li
              key={event.id}
              ref={element => utils.addItem(element)}
              className={classNames(s.item, utils.itemsHeightClass(width))}
              data-hook={DH.sideBySideItem}
            >
              <SideBySideItem event={event} evenItem={!!(index % 2)} t={t} />
            </li>
          ))}
        </ul>
        <div className={s.loadMoreWrapper}>
          <LoadMoreButton hasMore={hasMore} moreLoading={moreLoading} />
        </div>
      </div>
    </div>
  )
}
