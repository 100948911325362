import {ClockSmall} from '@wix/wix-ui-icons-common/on-stage'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {useEventDateInformation} from '../../../../../commons/hooks/dates'
import sc from '../classes.scss'
import s from './countdown.scss'
import {CountdownProps} from './interfaces'

export const Countdown = ({event}: CountdownProps) => {
  const {t} = useTranslation()
  const {daysLeft} = useEventDateInformation(event.id)

  return (
    <div className={classNames(sc.textLineHeight, s.root)} data-hook="countdown">
      <ClockSmall className={s.icon} size={16} />
      <div className={s.text}>{t('countdownDaysLeft', {daysLeft})}</div>
    </div>
  )
}
