import s from './side-by-side.scss'

const CONTAINER_WIDTHS = {
  MEDIUM: 1200,
  BIG: 1500,
  EXTRA_BIG: 1800,
}

export class Utils {
  items: HTMLElement[] = []

  addItem = (element: HTMLElement) => element && this.items.push(element)

  setItemsHeight = () => {
    this.items.forEach((item: HTMLElement) => this.setItemHeight(item, 'auto'))

    const maxItemHeight = this.findMaxItemHeight()

    this.items.forEach((item: HTMLElement) => this.setItemHeight(item, `${maxItemHeight}px`))
  }

  setItemHeight = (item: HTMLElement, height: string) => (item.style.height = height)

  findMaxItemHeight = () =>
    this.items.reduce((height: number, item: HTMLElement) => Math.max(height, item.getBoundingClientRect().height), 0)

  itemsHeightClass = (containerWidth: number) => {
    if (containerWidth) {
      if (containerWidth <= CONTAINER_WIDTHS.MEDIUM) {
        return s.small
      } else if (containerWidth <= CONTAINER_WIDTHS.BIG) {
        return s.medium
      } else if (containerWidth <= CONTAINER_WIDTHS.EXTRA_BIG) {
        return s.big
      }
    }

    return s.extraBig
  }
}
